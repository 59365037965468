import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function BackToSchoolWithPurpleCowInternet() {
    return (
        <LayoutNew>
            <Helmet>
                {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                <title>Back to School with Purple Cow Internet</title>
            </Helmet>
            <section className="px-4 py-8 mx-auto font-light prose prose-lg">
                <StaticImage
                    layout="constrained"
                    src="images/Four-Reasons-Why-Students-Choose-Purple-Cow.png"
                    alt="Purple Cow Internet - 4 Reasons Why Students Choose Purple Cow"
                />
                <br></br>
                <br></br>
                <h1>Back to School with Purple Cow Internet</h1>
                <p>As the back-to-school season approaches, it’s time for new wardrobes, new school supplies, and most
                    importantly… new internet.</p>
                <p>With a new semester on the horizon, there’s plenty to think about: new schedules, finding the easiest
                    electives, getting new textbooks, and mastering the art of making ramen noodles. With all this on
                    your plate, having reliable, affordable internet should be the least of your worries.</p>
                <p>That’s where Purple Cow Internet comes in. We offer internet that you can count on—whether you’re
                    cramming for an exam or procrastinating on that paper. Internet that just works.</p>
                <h2>Here are four reasons why Purple Cow Internet is the best choice for all your student needs:</h2>
                <h3>No Term Contracts</h3>
                <p>From campus dorms to student housing to moving back to your folks’ place, students are always on the
                    move and shouldn’t be tied down by annual contracts. At Purple Cow, we don’t do term contracts, so
                    our service fits perfectly with your 8-month student housing lease. And if you need to pause or
                    cancel, it won’t cost you a thing. Unlike that complicated college relationship, we’ll happily
                    welcome you back next semester—no strings attached.</p>
                <p>If you love Purple Cow Internet as much as we do and want to bring it back with you to your parents'
                    place, we’ll even cover the costs of moving your service to a new address. The greatest gift you can
                    give your folks after a semester away is you. The second greatest? Purple Cow Internet.</p>
                <h3>Save Big with Our Referral Program</h3>
                <p>Student life is expensive. Rent, food, textbooks… and let’s not forget, more textbooks. We get
                    it.</p>
                <p>At Purple Cow, we offer a way to save big on your monthly internet bill with our fantastic referral
                    program. Here’s how it works:</p>
                <ul>
                    <li>Share your referral link</li>
                    <li>For every friend who joins, you’ll get a credit on your account and they will get a discount.
                    </li>
                    <li>Watch those savings pile up!</li>
                </ul>
                <p>With our referral program, some Purple Cow customers have their internet covered for years. Imagine
                    how many textbooks you could buy with those savings!</p>
                <h3>Sustainably Priced</h3>
                <p>School can be full of surprises—pop quizzes, lab assignments, and that paper you forgot was due
                    yesterday. But one thing you don’t need to worry about is your internet bill.</p>
                <p>At Purple Cow, our prices are designed to last, giving you peace of mind with a monthly bill that
                    stays the same. No random charges, no annual price hikes, and no need to haggle for a better rate.
                    The price you see is the price you pay, plus tax, of course!</p>
                <h3>100% Through Text or Digital, 100% Simple</h3>
                <p>We know student life is busy.</p>
                <p>From lectures to the library to social events, your time is precious. That’s why Purple Cow’s
                    customer care team is 100% online, ready to help you whenever you need it. You can get in touch with
                    an agent quickly and easily, without the hassle of long hold times or transfers.</p>
                <p>Whether you have a question or need to manage your account, you can fire us a text at anytime ,
                    fitting seamlessly into your busy schedule. Need answers fast? Check out our handy FAQ or drop us a
                    message.</p>
                <p>At Purple Cow Internet, we understand how vital it is for students to stay connected. Whether you’re
                    researching a paper, attending an online course, taking an exam, or enjoying a well-deserved study
                    break by streaming your favorite series, Purple Cow Internet has you covered. And when you become
                    the next big thing—whether it’s as Prime Minister or a Nobel Prize winner—don’t forget about the
                    internet service that helped you get there!</p>
                <p>Oh, and if you’re not sure which plan is best for your student needs—whether you’re just covering the
                    basics or need max speeds for gaming—you can find more information here:
                    <a href="/">Which Internet Speed Should You Choose?</a>
                </p>
            </section>
        </LayoutNew>
    )
}
