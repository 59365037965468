/**
 * LayoutNew component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import HeaderNew from "./headerNew"
import FooterNew from "./footerNew"
import { Helmet } from "react-helmet"

const LayoutNew: React.FunctionComponent<{ headerNew?: boolean }> = ({
    children,
    headerNew = true,
}:any) => {
    return (
        <div className="flex flex-col justify-between min-h-screen body--lato">
            {headerNew && <HeaderNew />}
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>

            <div>{children}</div>
            <FooterNew />
        </div>
    )
}

export default LayoutNew
