import { Link } from "gatsby"
import React, { useState,useEffect } from "react"
import { useChargebee, useSiteMetadata } from "../hooks"
import { CHARGEBEE_SITE } from "../config"
import { Helmet } from "react-helmet"
import Bars from "@svg-icons/fa-solid/bars.svg"
import CloseEvent from "@svg-icons/fa-solid/close.svg"
import { StaticImage } from "gatsby-plugin-image"

const HeaderNew: React.FunctionComponent = () => {
    let [menuOpen, setMenuOpen] = useState(false)
    useChargebee()

    let isWaitingListMode, isServiceOutageMode = useSiteMetadata()
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://www.purplecowinternet.com/" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content="Nova Scotia's Fastest Growing Internet Service Provider!"
                />
                <meta
                    property="og:description"
                    content="Purple Cow is a Nova Scotia based business offering Internet TV and Phone. We offer amazing AMAZING customer service, internet, and are the best deal in town!"
                />
                <meta
                    name="description"
                    content="Purple Cow is a Nova Scotia based business offering Internet TV and Phone. We offer amazing AMAZING customer service, internet, and are the best deal in town!"
                />

                <meta property="og:image" content="https://purplecowinternet.com/images/linksharingphoto.png" />

                <meta property="og:image:secure_url" content="https://purplecowinternet.com/images/linksharingphoto.png" />

                <meta property="fb:app_id" content="2815092408601788" />

                <meta
                    name="facebook-domain-verification"
                    content="y51i42mib2v5mror4hyb4pvmpfyzff"
                />
            </Helmet>
            <header className={scroll ? "flex items-center shadow-lg" : "flex items-center shadow-none"}>
                <div className="container mx-auto px-4">
                    <div className="new-header-row">
                        <Link to="/">
                            <StaticImage
                                className="height-30"
                                layout="constrained"
                                src="../content/cow.png"
                                alt="Purple Cow Internet 💜🐄"
                            />
                        </Link>
                        <div className="flex items-center justify-end lg:hidden">
                            {isWaitingListMode ? (
                                <a
                                    className="inline-block px-4 py-2 text-white cursor-pointer bg-primary-700"
                                    href="https://purplecowinternet.squarespace.com/waiting-list"
                                >
                                    Join the Waiting List
                                </a>
                            ) : (
                                <Link
                                    className="inline-block px-4 py-2 cursor-pointer btn-bg-new"
                                   to="/join-the-herd"
                                >
                                    Join the Herd
                                </Link>
                            )
                            }
                        
                            <button
                                className="self-start block justify-self-end lg:hidden"
                                onClick={() => setMenuOpen(true)}
                            >
                                <Bars className="w-6 h-6 text-black-700 ml-4" />
                            </button>
                        </div>
                        <nav className="hidden lg:block">
                            <ul className="flex items-center justify-end">
                                <li>
                                    <Link className="" to="/internet/">Internet </Link>
                                </li>
                                <li>
                                    <Link className="" to="/tv/">TV </Link>
                                </li>
                                <li>
                                    <Link className="" to="/home-phone/">Phone </Link>
                                </li>
                                <li>
                                    <Link className="" to={`https://${CHARGEBEE_SITE}.chargebeeportal.com`}>My Account </Link>
                                </li>
                                <li>
                                    {isWaitingListMode ? (
                                        <a
                                            className="inline-block px-4 py-2 text-white cursor-pointer bg-primary-700"
                                            href="https://purplecowinternet.squarespace.com/waiting-list"
                                        >
                                            Join the Waiting List
                                        </a>
                                    ) : (
                                    <Link
                                        className="inline-block px-4 py-2 cursor-pointer btn-bg-new"
                                       to="/join-the-herd"
                                    >
                                        Join the Herd
                                    </Link>
                                    )}
                                </li>
                            </ul>
                        </nav>
                        {menuOpen ? (
                        // mobile
                        <nav className="fixed mobile-menu tracking-wider lg:hidden bg-primary-800">
                            <button
                                className="close-menu"
                                onClick={() => setMenuOpen(false)}
                                onKeyDown={(e) => e.key === "Enter" && setMenuOpen(false)}
                            >
                                <CloseEvent className="" /> 
                            </button>
                            <ul className="flex flex-col gap-4 text-2xl font-bold text-white">                                
                                <li>
                                    <Link className="" to="/internet/">Internet </Link>
                                </li>
                                <li>
                                    <Link className="" to="/tv/">TV </Link>
                                </li>
                                <li>
                                    <Link className="" to="/home-phone/">Phone </Link>
                                </li>
                                <li>
                                    <Link className="" to="/thoughts/">Thoughts</Link>
                                </li>
                                <li>
                                    <Link className="" to={`https://${CHARGEBEE_SITE}.chargebeeportal.com`}>My Account </Link>
                                </li>
                            </ul>
                        </nav>
                        ) : null}
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderNew
